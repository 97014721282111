<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.add") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="createRequest">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="displayRequest.name"
              :label="$t('labels.name')"
              class="c-input-small"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <select-multiple
              v-model="displayRequest.pos_ids"
              :options="posOptions"
              :label="$t('labels.pos')"
              custom-class="c-input-small"
            ></select-multiple>
          </v-col>
          <v-col cols="3">
            <date-picker-model
              v-model="displayRequest.start_time"
              :label="$t('labels.start')"
              :min-date="currentDate"
              :single-line="false"
            ></date-picker-model>
          </v-col>
          <v-col cols="3">
            <date-picker-model
              v-model="displayRequest.end_time"
              :label="$t('labels.end')"
              :min-date="currentDate"
              :single-line="false"
            ></date-picker-model>
          </v-col>
        </v-row>

        <v-simple-table
          class="table-padding-2-no-top mt-5"
          height="400px"
          fixed-header
        >
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center" style="width: 150px">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 200px">
                {{ $t("labels.note") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 120px">
                {{ $t("labels.area") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.name") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods_description") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.size") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 75px">
                {{ $t("labels.image") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                style="width: 50px; min-width: 50px"
              >
                <v-btn
                  color="primary"
                  x-small
                  class=""
                  @click="addItem"
                  style="min-width: 30px; height: 30px; padding: 0"
                >
                  <v-icon style="font-size: 24px">mdi-plus</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`dr_${key}_${item.id}`"
              class="text-center"
            >
              <td>
                <v-text-field
                  v-model="item.customer_goods_barcode"
                  type="text"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                  @keyup.enter="getOneGoods(item, key)"
                  @blur="getOneGoods(item, key)"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model.number="item.quantity"
                  type="number"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="item.note"
                  type="text"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <v-autocomplete
                  v-model="item.display_level"
                  :items="displayLevels"
                  type="text"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>{{ item.sku }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>
                <ImageViewer
                  width="auto"
                  height="40px"
                  v-if="item.url_images"
                  :url="item.url_images"
                />
              </td>
              <td style="width: 50px; min-width: 50px">
                <v-btn
                  color="error"
                  x-small
                  style="min-width: 20px; height: 20px; padding: 0"
                  @click="removeItem(item)"
                >
                  <v-icon style="font-size: 14px">mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { httpClient } from "@/libs/http";
const DEFAULT_ITEM = {
  customer_goods_barcode: null,
  quantity: null,
  note: null,
  display_level: null,
  id: null,
};
const DEFAULT_REQUEST = {
  name: null,
  pos_ids: [],
  start_time: null,
  end_time: null,
};

export default {
  name: "CreateDisplayRequest",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer.vue"),
  },
  data: () => ({
    posOptions: [],
    items: [],
    displayRequest: { ...DEFAULT_REQUEST },
    hasChange: false,
    displayLevels: [
      {
        text: "Ưu tiên 1",
        value: 1,
      },
      {
        text: "Ưu tiên 2",
        value: 2,
      },
      {
        text: "Ưu tiên 3",
        value: 3,
      },
    ],
  }),
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
    itemConverted() {
      const items = [];
      for (let p = 0; p < this.displayRequest.pos_ids.length; p++) {
        const id_pos = this.displayRequest.pos_ids[p];
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          items.push({
            id_pos,
            name: this.displayRequest.name,
            start_time: this.displayRequest.start_time,
            end_time: this.displayRequest.end_time,
            id_goods: item.id_goods,
            quantity: item.quantity,
            display_level: item.display_level,
          });
        }
      }
      return items;
    },
  },
  mounted() {
    const { poss } = window.me;
    this.posOptions = [...poss].map((w) => ({
      value: w.id,
      text: w.code || "",
    }));
    this.addItem();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    addItem() {
      this.items.unshift({
        ...DEFAULT_ITEM,
        id: this.generateRandomString(6),
      });
    },
    removeItem(item) {
      this.items = [...this.items].filter((i) => i.id !== item.id);
    },
    async getOneGoods(item, key) {
      if (!item.customer_goods_barcode) {
        return false;
      }

      try {
        const { data } = await httpClient.post("/get-goods-by-barcode", {
          customer_goods_barcode: item.customer_goods_barcode,
        });
        const items = [...this.items];
        items[key] = { ...data, id_goods: data.id, ...items[key] };
        this.items = [...items];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async createRequest() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-create-display-request", {
          items: this.itemConverted,
        });
        this.isLoading = false;
        this.hasChange = true;
        this.displayRequest = { ...DEFAULT_REQUEST };
        this.items = [];
        this.addItem();
        this.$vToastify.success(this.$t("messages.create_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
